<template>
  <div class="mask" v-if="isShowDialogs">
    <div class="dialogDIV">
      <div class="dialogTitle">
        <div class="titleDIV">
          <div class="title">{{title}}</div>
          <div class="subTitle">{{subTitle}}</div>
        </div>
        <div v-if="$route.path != '/profile/update'" class="closeBtn" @click="closeDialog"><icon name="batu-icon" class="closeIcon"/></div>
      </div>
      <div class="msgDIV">
        <div v-if="message" class="message" :class="messageColor">{{ message }}</div>
        <div v-if="html" class="html" :class="messageColor" v-html="html"></div>
        <slot/>
      </div>
      <div class="btnDIV">
        <div class="cancelBtn" @click="cancelClick" v-if="cancel">
          <span class="cancelText">{{cancel}}</span>
        </div>
        <div class="submitBtn" @click="submitClick" v-if="submit">
          <span class="submitText">{{submit}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isShowAlert", 'title', 'subTitle', "message", "html", "messageColor", "cancel", "submit"],
  data() {
    return {
      isShowDialogs: false,
    };
  },
  methods: {
    cancelClick() {
      this.isShowDialogs = false;
      document.body.style.overflow = "";
      this.$emit("cancelClick");
    },
    submitClick() {
      this.isShowDialogs = false;
      document.body.style.overflow = "";
      this.$emit("submitClick");
    },
    closeDialog() {
      this.isShowDialogs = false;
      document.body.style.overflow = "";
      this.$emit("closeDialog");
    }
  },
  watch: {
    isShowAlert() {
      this.isShowDialogs = this.isShowAlert;
      if (this.isShowAlert) {
        document.body.style.overflow = "hidden";
        // var tops = $(document).scrollTop()
        // $(document).bind("scroll",function (){
        //   $(document).scrollTop(tops);
        // });
      // } else {
      //   $(document).unbind("scroll");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.dialogDIV {
  max-width: 850px;
  height: fit-content;
  background: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  @include mq(sm) {
    min-width: 315px;
    width: 315px;
    display: flex;
    flex-direction: column;
  }
  .dialogTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
    position: relative;
    @include mq(sm) {
      height: 60px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.5px;
      border-bottom: 0.5px solid $black-50;
      bottom: -0.5px;
      @include mq(sm) {
        border-bottom: 1px solid rgba(15, 15, 17, 1);
        bottom: -1px;
      }
    }
    .titleDIV {
      display: flex;
      flex-direction: row;
      height: 100%;
      @include mq(sm) {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0px 9px 0px 20px;
      }
      .title {
        margin-left: 50px;
        height: 100%;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        text-align: left;
        letter-spacing: 0.05em;
        color: $black;
        @include mq(sm) {
          margin-left: 0px;
          white-space: nowrap;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black;
        }
      }
      .subTitle {
        margin-top: 32px;
        display: flex;
        margin-left: 20px;
        height: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.08em;
        color: $black;
        opacity: 0.5;
        text-align: left;
        @include mq(sm) {
          display: none;
          margin-top: 0px;
          padding-top: 24px;
          margin-left: 0px;
          white-space: nowrap;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black;
          opacity: 0.5;
        }
      }
    }
    .closeBtn {
      width: 80px;
      height: 80px;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include mq(sm) {
        width: 60px;
        height: 60px;
        min-width: 60px;
      }
      .closeIcon {
        width: 16px;
        height: 16px;
      }
    }
  }
  .msgDIV {
    display: flex;
    align-items: center;
    justify-content: center;
    .message, .html {
      white-space: pre-wrap;
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: 41px;
      margin-right: 41px;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.12em;
      @include mq(sm) {
        margin-top: 60px;
        margin-bottom: 60px;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.12em;
      }
    }
    .html {
      white-space: normal;
    }
    .primary {
      color: $primary;
    }
    .secondary {
      color: $secondary;
    }
    .black {
      color: $black;
    }
  }
  .btnDIV {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include mq(sm) {
      flex-direction: column;
    }
    .cancelBtn {
      width: 200px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $black;
      margin-right: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      @include mq(sm) {
        width: 275px;
        margin-left: 20px;
        margin-bottom: 20px;
      }
      .cancelText {
        font-weight: 700;
        font-size: 18px;
        line-height: 70px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $dark-grey;
        @include mq(sm) {
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1em;
          color: $dark-grey;
        }
      }
    }
    .submitBtn {
      width: 200px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      background: $black;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      @include mq(sm) {
        width: 275px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .submitText {
        font-weight: 700;
        font-size: 18px;
        line-height: 70px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $white;
        @include mq(sm) {
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1em;
          color: $white;
        }
      }
    }
  }
}
</style>