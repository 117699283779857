<template>
  <div class="login-infomation">
    <div class="login-infomation-icon">
      <img src="@/assets/img/mypage-icon.png" alt="" />
    </div>
    <div class="login-infomation-name">
      <div v-if="store.state.user.servicePlanId !== null" class="login-plan">
        <span class="en">{{ store.state.user.servicePlanId == 1 ? 'PREMIUM' : store.state.user.servicePlanId == 2 ? 'BUSINESS' : 'ENTERPRISE' }}</span>
        <span class="ja">{{ store.state.user.servicePlanId == 1 ? 'プレミアム' : store.state.user.servicePlanId == 2 ? 'ビジネス' : 'エンタープライズ' }}</span>
      </div>
      <div class="login-name">{{ store.state.user.name }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from 'vuex'
export default defineComponent({
  name: "loginUserInfomation",
  components: {},
  setup() {
    const store = useStore()
    const state = reactive({});

    return {
      store,
      state,
    };
  },
});
</script>
<style lang="scss" scoped>
.login-infomation {
  display: flex;
  align-items: center;
}
.login-infomation-icon {
  margin-right: 40px;
  box-shadow: 0px 20px 30px rgba(54, 131, 245, 0.25);
  @include mq(md) {
    img {
      width: 60px;
    }
  }
}
.login-infomation-name {
  text-align: left;
  .login-plan {
    font-size: 12px;
    color: rgba(89, 88, 87, 0.8);
    .en {
      margin-right: 10px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      color: $black;
    }

    &+.login-name {
      margin-top: 15px;
    }
  }
  .login-name {
    font-size: 40px;
    font-weight: 700;
    color: $black;
    @include mq(md) {
      font-size: 30px;
    }
  }
}
</style>