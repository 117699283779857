<template>
  <div class="tab-action">
    <ul class="tab-action-list">
      <li
        :class="item.isActive ? 'is-active' : ''"
        v-for="(item, index) in tabName"
        :key="index"
        class="tab-item"
        @click="clickEvent(index)"
      >
        {{ item.en }}<span class="ruby">{{ item.ja }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: ["tabCount", "tabName"],
  setup(props, context) {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      index: "0",
    });

    const clearHeaderToMypage = () => {
      store.commit("setHeaderToMypage", false);
    };

    onMounted(() => {
      if (store.state.user.headerToNoticeTab && route.name === "mypage") {
        const tabList = document.querySelectorAll(".tab-item");
        for (const item of tabList) {
          item.classList.remove("is-active");
        }
        tabList[3].classList.add("is-active");
        clearHeaderToMypage();
      }
    });

    const clickEvent = (index) => {
      if (route.name === "mypage") {
        const tabList = document.querySelectorAll(".tab-item");
        for (const item of tabList) {
          item.classList.remove("is-active");
        }
      }
      context.emit("tabClick", index);
    };

    return {
      clickEvent,
      store,
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab-action {
  overflow-x: auto;
}
.tab-action-list {
  display: inline-flex;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(100px);
  max-width: 100%;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 214px;
    padding: 20px 0;
    border-bottom: 4px solid transparent;
    font-size: 18px;
    font-weight: 700;
    font-family: "Roboto Condensed", sans-serif;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    @include mq(md) {
      padding: 20px;
      font-size: 14px;
    }
    @include mq(sm) {
      min-width: 140px;
    }
    &.is-active {
      background: rgba(255, 255, 255, 0.6);
      border-bottom: 4px solid $black;
    }
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: $black;
      transform: translate(-50%, -50%);
    }
    &:first-of-type {
      &::before {
        content: none;
      }
    }
    .ruby {
      display: block;
      font-family: "Noto Sans JP", sans-serif;
      margin-top: 0.5em;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      @include mq(md) {
        font-size: 12px;
      }
    }
  }
}
</style>
